import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import TestImage from "../images/advertisement/christmas.webp";
import { IoCloseCircleSharp } from "react-icons/io5";

export default function AdvertisementCard() {
    const [isVisible, setIsVisible] = useState(false); // Initially hidden

    // Animation settings
    const slideIn = {
        hidden: {
            x: 500, // Start from the right
            opacity: 0,
        },
        visible: {
            x: 0, // End at its original position
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 30,
            },
        },
    };

    // Handler to hide the advertisement
    const handleClose = () => {
        setIsVisible(false);
    };

    // Use useEffect to delay the appearance of the advertisement
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true); // Show the advertisement after a delay
        }, 1500); // Delay in milliseconds, adjust as needed

        // Cleanup function to clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    className="z-50 fixed bottom-0 right-0 m-4"
                    variants={slideIn}
                    initial="hidden"
                    animate="visible"
                    exit={{ x: 500, opacity: 0, transition: { duration: 0.5 } }}
                >
                    <div className="flex">
                        <IoCloseCircleSharp
                            className="text-3xl mx-3 text-gray-300 cursor-pointer"
                            onClick={handleClose}
                        />
                        <div className="bg-white w-96 sm:w-80 shadow-md shadow-gray-400">
                            <img src={TestImage} alt="Ramadhan Advertisement" />
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
